import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import PropTypes from 'prop-types';

import HeaderFull from './header/header-full';
import FooterNewUpdate from './footer-new-update';
import SectionCookieNoticeNew from './sections/section-cookie-notice-new';
import SectionCookieNoticeMobileNew from './sections/section-cookie-notice-mobile-new';
import PopUp from '../components/common/PopUp';

const LayoutUpdate = ({ children }) => {
  const data = useStaticQuery(graphql`
    query LayoutUpdateQuery {
      prismicPromotionalPopUp {
        data {
          activate_pop_up
          top_title
          main_title
          short_description
          date
          button_name
          button_link
          background_image {
            gatsbyImageData(
              width: 1920
            )
          }
        }
      }      
      imgDefault: file(relativePath: { eq: "backgrounds/pop-up-bg.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1920)
        }
      }
    }
  `);

  const pop = data.prismicPromotionalPopUp;
  const imgPopUp = getImage(pop.data.background_image);
  const imgDefault = getImage(data.imgDefault);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const timeDelay = 5000; // 5 seconds delay before showing popup

  const localStorageKey = 'popupCache';

  // Ensure our cache object exists and is in sync with the CMS toggle value.
  useEffect(() => {
    const cache = localStorage.getItem(localStorageKey);
    let cacheObj = cache ? JSON.parse(cache) : null;
    // If there is no cache or if the stored CMS toggle is different from the current one,
    // reset the cache.
    if (!cacheObj || cacheObj.activate_pop_up !== pop.data.activate_pop_up) {
      cacheObj = { activate_pop_up: pop.data.activate_pop_up, lastShown: null };
      localStorage.setItem(localStorageKey, JSON.stringify(cacheObj));
    }
  }, [pop.data.activate_pop_up]);

  // Timer effect: Only show the popup if the CMS says it’s active and it hasn’t been shown in the last 24 hours.
  useEffect(() => {
    if (!pop.data.activate_pop_up) return; // Exit early if popup is deactivated

    const now = new Date().getTime();
    const cache = localStorage.getItem(localStorageKey);
    const cacheObj = cache ? JSON.parse(cache) : {};

    // If lastShown is null or older than 24 hours, set a timer to show the popup.
    if (!cacheObj.lastShown || now - cacheObj.lastShown > 24 * 60 * 60 * 1000) {
      const timer = setTimeout(() => {
        setIsPopupOpen(true);
        // Update the cache with the current timestamp
        localStorage.setItem(localStorageKey, JSON.stringify({ 
          activate_pop_up: pop.data.activate_pop_up, 
          lastShown: now 
        }));
      }, timeDelay);

      return () => clearTimeout(timer);
    }
  }, [pop.data.activate_pop_up, timeDelay]);

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && window.Chatlio) {
      window.Chatlio.init('d932bc71-e6bc-4d0a-7bfc-22ebd2283d28');
    }
  }, []);

  const openChat = (e) => {
    e.preventDefault();
    if (typeof window !== 'undefined' && window._chatlio) {
      window._chatlio.open();
    } else {
      console.error('Chatlio not initialized');
    }
  };

  return (
    <>
      <HeaderFull />
      <div>
        <main>{children}</main>
      </div>
      <chatlio-widget widgetid="d932bc71-e6bc-4d0a-7bfc-22ebd2283d28"></chatlio-widget>
      <FooterNewUpdate />
      <SectionCookieNoticeNew />
      <SectionCookieNoticeMobileNew />
      {/* Popup Component */}
      {pop.data.activate_pop_up &&
        <PopUp isOpen={isPopupOpen} onClose={closePopup} backgroundImage={imgPopUp ? imgPopUp : imgDefault}>
          <div className="w-full flex flex-col items-center justify-center mx-auto">
            <div className="z-10">
              <h3 className="text-site-red text-xl md:text-2xl text-center font-bold mb-4">
                {pop.data.top_title || "Top Title Here"}
              </h3>
              <h2 className="text-site-red text-4xl md:text-6xl text-center font-bold uppercase mb-8">
                {pop.data.main_title || "Main Title Here"}
              </h2>
              <p className="text-base md:text-xl mb-6 text-center leading-7 md:leading-9">
                {pop.data.short_description || "Short description here."}
              </p>
              <p className="text-xl md:text-3xl font-bold text-center uppercase mb-8">
                {pop.data.date || "January 1, 2000"}
              </p>
              <div className='flex flex-col items-center justify-center'>
                <a
                  href={pop.data.button_link || "https://www.831b.com"}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={closePopup}
                >
                  <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-16 py-3">
                    {pop.data.button_name || "Button Name"}
                  </button>
                </a>
              </div>
            </div>
          </div>
        </PopUp>
      }
    </>
  );
};

LayoutUpdate.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutUpdate;