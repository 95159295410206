import React, { useEffect, useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const StyledGatsbyImage = styled(GatsbyImage)`
  position: absolute;
  inset: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 0.25rem;
  z-index: -1;
`;

const PopUp = ({ isOpen, onClose, children, backgroundImage }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true); // Make popup visible when opening
      setTimeout(() => setAnimate(true), 10); // Trigger animation after visible
      document.body.classList.add('no-scroll'); // Prevent body scroll
    } else {
      setAnimate(false); // Stop animation when closing
      setTimeout(() => setIsVisible(false), 300); // Hide popup after animation ends
      document.body.classList.remove('no-scroll'); // Re-enable body scroll
    }

    // Clean up body scroll restriction on unmount
    return () => document.body.classList.remove('no-scroll');
  }, [isOpen]);

  const handleClose = () => {
    setAnimate(false);
    onClose(); // Trigger the onClose callback
  };

  if (!isVisible) return null; // Don’t render if not visible

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 transition-opacity duration-1000 ease-in-out">
      {/* Dark background overlay */}
      <div className="bg-black opacity-50 absolute inset-0"></div>

      {/* Popup content */}
      <div
        className={`relative w-11/12 md:w-6/12 max-w-[90vw] md:max-w-[50vw] lg:max-w-[40vw] p-6 md:p-8 rounded shadow-lg z-10 bg-white transform transition-transform duration-1000 ease-in-out overflow-y-auto max-h-[90vh] ${
          animate ? 'translate-y-0' : 'translate-y-full'
        }`}
        style={{
          maxHeight: '90vh', // Allows content to take up to 90% of viewport height
          maxWidth: '90vw', // Allows content to take up to 90% of viewport width
        }}
      >
        {backgroundImage 
          ?
          <StyledGatsbyImage image={backgroundImage} alt="Popup background" />
          :
          null
        }

        {/* Close button */}
        <button
          className="absolute bg-site-green hover:bg-site-red w-10 h-10 md:w-12 md:h-12 top-0 right-0 text-white text-3xl md:text-4xl font-semibold"
          onClick={handleClose}
          style={{ zIndex: 10 }}
        >
          X
        </button>

        {/* Popup content */}
        <div className="relative z-10">{children}</div>
      </div>
    </div>
  );
};

export default PopUp;